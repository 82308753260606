const config = {
    toko_id: 'jakarta',
    daftar_toko: [
        {
            id: 'jakarta',
            nama: 'Jakarta',
            website_url: 'https://7aquo.id/',
            api_url: 'https://aquo.sawopos.com/shop/',
            show_harga_grosir: false,
        },
    ]
}
export default config